<template>
  <div class="container-fluid cart">
    <div class="row subbar">
      <div class="container-fluid">
        <div class="side-push">
          <div class="row">
            <div class="col">
              <nav>
                <span
                  ><a href="/">{{ $t("menu.home") }}</a></span
                >
                <span>/<a href="/">Support</a></span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      {{ getHookURL() }}
      {{ getPunchout }}
    </div>
  </div>
</template>

<script>
import InputAPI from "@/services/api/input";

export default {
  props: {},
  methods: {
    getHookURL() {
      let url = this.getPunchout.HOOK_URL;

      if (Array.isArray(url)) {
        return url.find((item) => item.length !== 0);
      }
      return url;
    },
  },
  computed: {
    getPunchout() {
      return this.$store.getters.getPunchout;
    },
  },
  mounted() {
    this.$store.dispatch("getCarts");
  },
  data() {
    return {
      debounceTimer: false,
      currentCart: {},
      editTitle: false,
      showNewCartForm: false,
      edit: {
        other_information: false,
        customer: false,
        reference: false,
        order_number: false,
      },
      cart: {
        name: "",
      },
    };
  },
};
</script>
